import React from 'react';

import SubLayout from '../components/SubLayout';
import MedicalGuidanceArea from '../components/MedicalGuidanceArea';

import { useTrackedState } from '../lib/state';
import { P } from '../components/common/P.style';

const Departments = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="診療科">
      <div
        css={`
          margin: 40px 0;
        `}
      >
        <MedicalGuidanceArea size={size} />
      </div>
    </SubLayout>
  );
};

export default Departments;
